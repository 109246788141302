.text {
  p {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 3rem;
  }

  .skillContainer {
      display: flex;
      align-items: flex-start;
  }

  .skillsSection, 
  .skillDescription {
      padding: 40px;
  }

  h4 {
    font-size: 18px;
    margin-bottom: 20px;
}


  h5 {
     margin-top: 20px;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 300;
      writing-mode: vertical-lr;
       transform: rotate(180deg); 
  }
  p {
    font-size: 16px;
    margin-bottom: 20px;
}
@media (max-width: 768px) { 
    .skillContainer {
        padding: 20px;
    }
    .skillsSection, 
    .skillDescription {
        padding: 0px;
    }
    h5{
        display: none;
    }

}

}





