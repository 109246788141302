.contact {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1; 
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 


  .contactContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    background: rgba(255, 255, 255, 0.1); 
    backdrop-filter: blur(10px); 
    border-radius: 20px; 
  }

  .contactContainer h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #333;
  }

  .contactContainer p {
    font-size: 1rem;
    color: black;
    text-align: center;
  }

  .buttonRow {
    display: flex;
    justify-content: space-between;
  }

  .buttonRow button {
    margin: 20px;
  }
  @media (max-width: 750px) {
    .contact {
      width: 90vw;
    }
    .contactContainer {
      width: 90vw; /* set the width to 80% of the viewport width, or adjust as needed */
      margin: 20px;
    }

    .buttonRow {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
}

}
