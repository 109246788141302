.projectsContainer {
  display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem; 
    padding: 20px;
    
  }

@media (max-width: 768px) { 
  .projectsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    gap: 1rem; 

    .projectsContainer > *:nth-child(9) {
      display: none;
    }
  }
}

.cardLoading {
  border: 16px solid #f3f3f3; 
  border-top: 16px solid black;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
