a{
  text-decoration: none;
}

.flipNavWrapper {
  position: relative; 
  width: 100%; 
  z-index: 1000; 
  align-items: center;
  justify-content: center;
  background-color: #EEEEEE;;
  
  &.indexPage {
    position: absolute;
    background-color: transparent;
  }
}


.flipNav {

  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  img{
    height: 1rem;
    @media (max-width: 750px) {
      height: 1.5rem;
    }
  }
}

.navLeft {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.navLeftButton {
  display: block;
  border: 0px;
  background-color: transparent;
  @media (min-width: 750px) {
    display: none;
  }
  color: #2d3748;
  font-size: 1.5rem;
}

.navLinks {
  display: none;
  height: 30px;
  overflow: hidden;
  font-weight: 500;
  
  @media (min-width: 750px) {
    display: block;
  }
}

.navRight {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.navRightButton,
.signInButton {
  padding: 1rem;
  background-image: linear-gradient(to right, #7C3AED, #4C1D95);
  color: transparent;
  background-clip: text;
  font-weight: 500;
  border-radius: 0.375rem;
  white-space: nowrap;
  
  &:hover {
    background-image: linear-gradient(to right, #4C1D95, #7C3AED);
  }
}

.signInButton {
  color: white;
}

.navMenuOpen {
  margin: 0;
  position: absolute;
  padding: 1rem;
  background-color: #EEEEEE;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  left: 0;
  right: 0;
  top: 100%;
  transform-origin: top;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100vw; 
  &.indexPage {
    background-color: #DFDFDF;
  }
}


.menuLinks,
.menuLinksItems {
  display: flex;
  align-items: center;
  height: 30px;
  font-weight: 500;
  text-decoration: none;
  color: black;
  
 
}

.menuLinks {
  overflow: hidden;
  font-size: 1.125rem;
  gap: 0.5rem;
}

.faIcon {
  height: 30px;
  color: #2d2f36;
}

.graytextSpan {
  display: flex;
  align-items: center;
  height: 30px;
  color: gray;
}

.blacktextSpan {
  display: flex;
  align-items: center;
  height: 30px;
  color: black;
}

@media screen and (max-width: 750px){
  .navLink{
    display: none;
  }
}

@media screen and (max-width: 750px){
  

    .navLeft {
      display: flex;
      justify-content: space-between;
      width: 100%; 
    }
  }



.rightContent {
  display: flex; // Ensuring that the children (PhoneIcon and EmailIcon) are in a row
  align-items: center; // Vertically center align if needed
  gap: 1rem; // Space between PhoneIcon and EmailIcon
  margin-left: auto; // Pushing the content to the right
}

.phoneIcon, .emailIcon {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  svg {
    font-size: 1.5rem;
    color: white;
  }

  .phoneNumber, .emailAddress {
    color: white;
    font-size: 1rem;
  }
}

