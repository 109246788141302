.projectDetail {
    width: 100%;

}

.banner {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; 
}

.banner img { 
    height: 400px;
    width: 90vw; 
    object-fit: cover;  
    border-radius: 20px;

}

.contentRow {
    display: flex;
    justify-content: center; 
    align-items: flex-start;
    padding: 40px 0;
    width: 90vw;
    max-width: 1200px;
    margin: 0 auto;
}


.projectOverview {
    margin-right: 20px;
    width: 50%;

    h3{
        margin-bottom: 20px;  
    }
}

.projectContributions {
    width: 50%;
    h3{
        margin-bottom: 20px;  
    }
}

.contentRowDetail{
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}

.projectToolset, 
.projectTags {

    flex: 0.4;  
    box-sizing: border-box;
    padding-top: 20px;
    margin-right: 20px;
    
}

.fullWidthLine {
    margin: 40px 0 ;
} 

.projectDesign {
    display: flex;
    flex-direction: column; 
    align-items: center;    
    padding: 40px 0;        
    width: 90vw;
    max-width: 1200px;   
    margin: 0 auto;       
}

.projectDesign {
    display: flex;
    flex-direction: column; 
    align-items: flex-start;         
    width: 90vw;
    max-width: 1200px;      
    margin: 0 auto;
    h3{
        margin-bottom: 20px;
    }     
    p{
        margin-bottom: 20px;
    }              
}
.imageRow {
    display: flex;
    overflow: hidden;
    width: 90vw;
    padding-bottom: 40px;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .secondImage,
  .thirdImage {
    flex: 1; /* Make them occupy full width on smaller screens */
    margin-bottom: 20px; /* Add spacing between the images */
  }
  
  .secondImage img {
    width: 100%; /* Adjust width for smaller screens */
    height: auto;
    object-fit: cover;
  }

  .thirdImage img {
    width: 90%; /* Adjust width for smaller screens */
    height: auto;
    object-fit: cover;
  }
  
  @media (max-width: 768px) {
    .imageRow {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
}

@media (max-width: 480px) {
    .imageRow {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .secondImage, 
    .thirdImage {
        width: 100%; /* set to desired width */
    }

    .secondImage img,
    .thirdImage img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}
