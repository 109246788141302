.card {
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  transition: all 0.3s;

  height: 400px;
  padding: 0; 

  .cardDetail{
    position: absolute;
    bottom: 10%;
    left: 10%;
    color: white;
    p{
      color: white;
    }

    .cardTitle, .cardDescription {
      opacity: 0;
        transition: opacity 0.3s;
        pointer-events: none;
    }
}

&:hover {
  transform: translateY(-5px);
  // cursor: url('path-to-your-custom-cursor.png'), pointer;

  .cardTitle, .cardDescription {
    opacity: 1;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }


}
}

@media (max-width: 750px) {
  .cardTitle h3{
    font-size: 1rem;
  }
  .card{
    height: 200px;
  }
}
