
.btnContainer {
padding: 20px 0px;

}

.neumorphismButton {
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.neumorphismButton:hover {
  box-shadow: -1px -1px 5px rgba(255, 255, 255, 0.6), 1px 1px 5px rgba(0, 0, 0, 0.3), inset -2px -2px 5px rgba(255, 255, 255, 1), inset 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: grey;
}

@media (max-width: 768px) {
  
.btnContainer {
  padding: 0px;
  }
}




