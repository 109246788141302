@import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto:wght@300&display=swap');


* {
  box-sizing: border-box;
}

html,
body,
#root {

  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background: #EEEEEE

}

body {
  font-family: "Inter var", sans-serif;
  cursor: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMwLjUgMTZDMzAuNSAyNC4wMDgxIDI0LjAwODEgMzAuNSAxNiAzMC41QzcuOTkxODcgMzAuNSAxLjUgMjQuMDA4MSAxLjUgMTZDMS41IDcuOTkxODcgNy45OTE4NyAxLjUgMTYgMS41QzI0LjAwODEgMS41IDMwLjUgNy45OTE4NyAzMC41IDE2WiIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNNiA5QzcuNjU2ODUgOSA5IDcuNjU2ODUgOSA2QzkgNC4zNDMxNSA3LjY1Njg1IDMgNiAzQzQuMzQzMTUgMyAzIDQuMzQzMTUgMyA2QzMgNy42NTY4NSA0LjM0MzE1IDkgNiA5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg=="),
    auto;
}

p {
  margin: 0;
  padding: 0;
}


h1 {
  font-family: "Antonio", sans-serif;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

h2 {
  font-family: "Antonio", sans-serif;
  font-size: 2rem;
  margin-bottom: 20px;
}

h3 {
  font-family: "Antonio", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -webkit-text-size-adjust: unset;
  color: #7e7e7e;
  background-color: white;
  border: none;
}

button:hover {
  color: black; 
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  margin: 0 auto;
  color: black;
}


a {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  padding-right: 10px;
  cursor: pointer;
  pointer-events: all;
  color: black;
  text-decoration: none; /* no underline */
}

@media screen and (max-width: 568px) {
  .full {
    visibility: hidden;
    display: none;
  }
  .content-container p {
    margin-bottom: 20px;
  }
  
}
