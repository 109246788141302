.contentContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1; /* To make sure it's below the nav but on top of the Three.js animation */
    
    /* Flexbox styles */
    display: flex;
    flex-direction: column; /* stack children vertically */
    align-items: center; /* center children horizontally */
    justify-content: center; /* center children vertically */
  }

  @media (max-width: 750px) {
    .contentContainer p{
      margin-bottom: 20px;
    }
  }